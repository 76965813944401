// @flow
import * as React from "react";
import {useState} from "react";
import {css, StyleSheet} from 'aphrodite';

import {violetHue, orangeHue, greenHue} from "../constants";
import {hslToHex} from "../utils";

const SocialMediaButton = ({viewBox, d}: {|
    viewBox: string,
    d: string,
|}) => {
    const [isMouseEnter, setIsMouseEnter] = useState(false);

    return (
        <div
            className={css(styles.container)}
            onMouseEnter={() => setIsMouseEnter(true)}
            onMouseLeave={() => setIsMouseEnter(false)}
        >
            <svg
                className={css(styles.image)}
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox={viewBox}>
                >
                <path
                    className={css(isMouseEnter && styles.hover)}
                    fill="#555555" d={d}
                />
            </svg>
        </div>
    );
};

const translateKeyFrames = {
    '0%': {
        fill: "#555555",
    },
    '75%': {
        fill: hslToHex(violetHue, 25, 70),
    },
    '100%': {
        fill: hslToHex(violetHue, 25, 65),
    },
};

const styles = StyleSheet.create({
    hover: {
        animationName: [translateKeyFrames],
        animationDuration: `300ms`,
        animationIterationCount: 1,
        animationFillMode: "both",
        transformOrigin: "center",
        perspectiveOrigin: "center",
    },
    container: {
        width: 20,
        padding:10,
    },
    image: {
        flexGrow: 1,
    },
});


export default SocialMediaButton;
