// @flow
import {css, StyleSheet} from "aphrodite";
import React, {useState} from "react";

import LogoPart from "./LogoPart";
import {createAnimationKeyFrames, createPathAnimationKeyFrames, hslToHex} from "../../utils";
import {greenHue, orangeHue, violetHue} from "../../constants";

const eColor = hslToHex(greenHue, 90, 40);
const hColor = hslToHex(greenHue, 90, 50);
const rColor = hslToHex(greenHue, 90, 70);

const disableAnimations = false;
const verticalStartZ = 0;
const verticalAnimationDuration = 750;
const verticalYDistance = 500;
const verticalAnimationOptions = {
    startOpacity: 0,
    animationDuration: verticalAnimationDuration,
    startZ: verticalStartZ,
    animationDelay: 0,
};

const nonVerticalStartZ = 200;
const nonVerticalAnimationOptions = {
    startZ: nonVerticalStartZ,
    startRotateY: 1,
    startRotateZ: 1,
    startRotateAngle: 90,
};

const getRandomColor = () => {
    const hues = [greenHue, orangeHue, violetHue];
    return hslToHex(hues[Math.floor(Math.random()*3)], 75 + Math.floor(Math.random() * 25), 25 + Math.random() * 50);
}

const logoParts = [
    //Vertical Lines
    {
        name: "e-vertical",
        d: "M15,54H0V0H15",
        fill: eColor,
        animationOptions: {
            ...verticalAnimationOptions,
            startY: -verticalYDistance,
            animationDelay: 50,
        },
    }, {
        name: "r-vertical",
        d: "M82,35V54H67V0H82m0,25,.53-13",
        fill: rColor,
        animationOptions: {
            ...verticalAnimationOptions,
            startY:verticalYDistance,
            animationDelay: 100,
        },
    }, {
        name: "h-vertical-left",
        d: "M43,54H29V0H43",
        fill: hColor,
        animationOptions: {
            ...verticalAnimationOptions,
            startY: verticalYDistance,
            animationDelay: 150,
        },
    },{
        name: "h-vertical-right",
        d: "M50,0H65V54H50",
        fill: hColor,
        animationOptions: {
            ...verticalAnimationOptions,
            startY: -verticalYDistance
        },
    }, {
        //NON-Vertical Lines
        name: "e-horizontal-bottom",
        d: "M15,41H27V54H0V41",
        fill: eColor,
        animationOptions: {
            ...nonVerticalAnimationOptions,
            startRotateAngle: -90,
        },
    }, {
        name: "r-remaining",
        d: "M82.94,34.76,90.39,54h16.3L95.48,31.55q8.64-3.75,8.64-13.54,0-9.17-6.43-14.13C94.31,1.29,88.8,0,82,0H69M87.23,23.06A5.42,5.42,0,0,1,83,25h0V12h-.47q6.39,0,6.39,6.11A7.27,7.27,0,0,1,87.23,23.06Z",
        fill: rColor,
        animationOptions: {
            ...nonVerticalAnimationOptions,
        },
    },{
        name: "e-horizontal-top",
        d: "M0,13V0H27V13",
        fill: eColor,
        animationOptions: {
            ...nonVerticalAnimationOptions,
            startRotateAngle: -90,
            startY: -40,
        },
    },{
        name: "e-horizontal-middle",
        d: "M0,20H26V33H0",
        fill: eColor,
        animationOptions: {
            ...nonVerticalAnimationOptions,
            startRotateAngle: 90,
            startY: -80
        },
    }, {
        name: "h-horizontal",
        d: "M65,19V32H29V19H50",
        fill: hColor,
        animationOptions: {
            ...nonVerticalAnimationOptions,
        },
    },
];
const svgAnimationStyleName = "svgAnimation";
const pathAnimationStyleName = "pathAnimation";
const hoverLogoStyleName = "hoverLogoAnimation";

const svgAnimations = logoParts.reduce((acc, {animationOptions}, index) => {
    acc[`${svgAnimationStyleName}${index}`] = disableAnimations ? {} : createAnimationKeyFrames({
        animationDelay: index * 300,
        ...animationOptions,
    });

    return acc;
}, {});

const pathAnimations = logoParts.reduce((acc, {fill}, index) => {
    const key = `${pathAnimationStyleName}${index}`;
    
    acc[key] = createPathAnimationKeyFrames({
        fill,
    });

    return acc;
}, {});

const hoverLogoAnimations = logoParts.reduce((acc, {}, index) => {
    const key = `${hoverLogoStyleName}${index}`;

    acc[key] = {
        fill: getRandomColor(),
    };

    return acc;
})

const Logo = () => {
    const [isMouseEnter, setIsMouseEnter] = useState(false);

    return (
        <a
            className={css(styles.logoContainer)}
            href="/"
            onMouseEnter={() => setIsMouseEnter(true)}
            onMouseLeave={() => setIsMouseEnter(false)}
        >
            {logoParts.map(({name, d, animationOptions}, index) => (
                <LogoPart
                    isMouseEnter={isMouseEnter}
                    hoverLogoStyle={styles[`${hoverLogoStyleName}${index}`]}
                    svgStyle={styles[`${svgAnimationStyleName}${index}`]}
                    pathStyle={styles[`${pathAnimationStyleName}${index}`]}
                    name={name}
                    d={d}
                />
            ))}
        </a>
    );
};

/*
.erik-personal-site-1-hsla { color: hsla(148, 100, 50, 1); }
.erik-personal-site-2-hsla { color: hsla(148, 51, 46, 1); }
.erik-personal-site-3-hsla { color: hsla(39, 100, 69, 1); }
.erik-personal-site-4-hsla { color: hsla(38, 100, 36, 1); }
.erik-personal-site-5-hsla { color: hsla(278, 34, 38, 1); }
 */
const styles = StyleSheet.create({
    ...pathAnimations,
    ...svgAnimations,
    ...hoverLogoAnimations,
    logoContainer: {
        width: 200,
        height: 101,
        transformOrigin: "center",
        perspectiveOrigin: "center",
        position: "relative",
    },
});

export default Logo;